
import { defineComponent } from 'vue';
import Datatable from '@/components/kt-datatable/KTDatatable_coursetype.vue';
import AddCourseTypeDrawer from '@/layout/header/partials/course/AddCourseDrawer.vue';
import EditCourseDrawer from '@/layout/header/partials/course/EditCourseDrawer.vue';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.js';

export default defineComponent({
  name: 'course-type',
  components: {
    Datatable,
    AddCourseTypeDrawer,
    EditCourseDrawer,
  },
  data() {
    return {
      componentKey: 0,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
          width: '120px',
        },

        {
          name: 'Course Type',
          key: 'course_type',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Percentage Enrollment',
          key: 'percentage_enrollment',
          sortable: true,
        },
        {
          name: 'Percentage Certification',
          key: 'percentage_certification',
          sortable: true,
        },
        {
          name: 'Percentage Employment',
          key: 'percentage_employment',
          sortable: true,
        },
        {
          name: 'Employement Restriction Percentage',
          key: 'emp_restriction_percentage',
          sortable: true,
          width: '200px',
        },
      ],
      lists: [],
      search: '',
      tableData: [],
      data: {},
    };
  },
  async created() {
    await this.getData();
    Object.assign(this.tableData, this.lists);

    this.emitter.on('course-added', async () => {
      await this.getData();
      this.tableData = this.lists;
      this.componentKey += 1;
    });
  },
  methods: {
    async getData() {
      await ApiService.get('configurations/course_type/list_course_type')
        .then((response) => {
          this.lists = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    editCourse(data) {
      this.emitter.emit('course-edit-type', data);
    },
    searchItems() {
      if (this.search !== '') {
        let results: never[] = [];
        for (let j = 0; j < this.tableData.length; j++) {
          if (this.searchingFunc(this.tableData[j], this.search)) {
            results.push(this.tableData[j]);
          }
        }
        this.tableData.splice(0, this.tableData.length, ...results);
      } else {
        Object.assign(this.tableData, this.lists);
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === 'object')) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    edit(data) {
      data.componenyKey = this.componentKey;
      this.emitter.emit('edit-modal-data', data);
    },

    add() {
      this.emitter.emit('add-modal-data', true);
    },

    view(divison) {
      this.data = divison;
    },

    DeleteCourse(id) {
      Swal.fire({
        title: 'Are you sure you want to delete it?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete!',
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete('configurations/course_type/delete/' + `${id}`)
            .then((response) => {
              this.emitter.emit('course-added', true);
              Swal.fire('Deleted!', response.data.data, 'success');
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
